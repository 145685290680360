<template>
  <div class="signup_page">
    <div class="content">
      <div class="signup_form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label class="form_title">
            <h2>手机号注册</h2>
          </el-form-item>
          <!-- <el-form-item label prop="username">
            <el-input v-model="ruleForm.username" placeholder="请输入昵称" autocomplete="off"></el-input>
            <div class="tip">2-20位，仅支持汉字字母数字和下划线，不能有空格</div>
          </el-form-item> -->
          <el-form-item label="" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入手机号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="code" class="phone_code_wrap">
            <el-input
              v-model="ruleForm.code"
              placeholder="短信验证码"
              autocomplete="off"
            ></el-input>
            <span v-if="!countdown" class="get_phone_code" @click="getSmsCode"
              >获取短信验证码</span
            >
            <span v-else class="get_phone_code count_down"
              >{{ countdownTime }}s后重新获取</span
            >
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              placeholder="请输入密码"
              autocomplete="off"
            ></el-input>
            <div class="tip">不少于6位</div>
          </el-form-item>
          <el-form-item label="" prop="city">
            <el-input
              v-model="ruleForm.city"
              placeholder="请输入您的城市"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="level">
            <div v-if="ruleForm.level" @click="selectLevel" class="level_wrap">
              {{ ruleForm.level }}
            </div>
            <div
              v-else
              class="level_wrap placeholder_level"
              @click="selectLevel"
            >
              请选择年级
            </div>
            <!-- <el-select v-model="ruleForm.level" placeholder="请选择年级">
              <el-option
                v-for="(item, index) in levelOptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="">
            <el-button
              type="primary"
              class="signup_btn"
              @click="submitForm('ruleForm')"
              >注 册</el-button
            >
          </el-form-item>
        </el-form>
        <div class="login_right_now">
          已有账号，
          <router-link to="/signin">马上登录</router-link>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model="levelShow"
      title="年级"
      @select="onSelect"
      @cancel="levelShow = false"
    >
      <ul class="level_modal_nr">
        <li
          class="level_item"
          v-for="(item, index) in levelOptions"
          :key="index"
          :class="{ active_level: activeLevel == item.name }"
        >
          <div @click="selectLevelItem(item)">
            {{ item.name }}
          </div>
        </li>
      </ul>
    </van-action-sheet>
  </div>
</template>

<script>
import { encrypt } from "@/utils";

export default {
  layout: "login",
  data() {
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      const reg = /^[1][3,4,5,7,8,6,9][0-9]{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };

    return {
      levelOptions: [{ name: "高一" }, { name: "高二" }, { name: "高三" }],
      // levelOptions: ["高一", "高二", "高三"],
      levelShow: false,
      activeLevel: "",
      countdown: false,
      countdownTime: 60,
      ruleForm: {
        phone: "",
        password: "",
        code: "",
        level: ""
      },
      timer: "",
      rules: {
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
        ],
        code: [
          { required: true, message: "请输入手机验证码", trigger: "blur" }
        ],
        city: [{ required: true, message: "请输入您的城市", trigger: "blur" }],
        level: [{ required: true, message: "请选择年级", trigger: "change" }]
      }
    };
  },
  created() {
    clearInterval(this.timer);
    this.ruleForm = {
      phone: "",
      password: "",
      code: ""
    };
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    selectLevel() {
      this.levelShow = true;
    },
    onSelect(item) {
      this.ruleForm.level = item.name;
      this.activeLevel = item.name;
      this.levelShow = false;
    },
    selectLevelItem(item) {
      this.ruleForm.level = item.name;
      this.activeLevel = item.name;
      this.levelShow = false;
    },
    async getSmsCode() {
      if (!this.ruleForm.phone) {
        this.$toast("请填写手机号");
      } else {
        if (this.ruleForm.phone.length != 11) {
          this.$toast("请输入正确的手机号");
        } else {
          const _this = this;

          const response = await this.$axios.post("/api/sms/", {
            phone: this.ruleForm.phone
          });
          if (response.data.errno == 0) {
            this.$toast("验证码已发送");

            _this.countdown = true;
            _this.timer = setInterval(() => {
              if (_this.countdownTime > 1) {
                _this.countdownTime--;
              } else {
                _this.countdown = false;
                clearInterval(_this.timer);
              }
            }, 1000);
          }
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const response = await this.$axios.post(
            "/api/user/register/",
            this.ruleForm
          );

          if (response.data.errno == 0) {
            this.$cookies.set(
              "yzy-token",
              encrypt(response.data.results.token)
            );
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.results.user)
            );
            this.$store.commit("SET_USER", response.data.results.user);
            this.$router.push({ name: "LoginResult" });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.signup_page {
  overflow: hidden;
  background: #fff;
  min-height: 100vh;
  .van-action-sheet__item {
    font-size: 0.25rem;
  }
  .content {
    padding-top: 0.2rem;
    .signup_form {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 1rem;
      background: #fff;
      border-radius: 4px;
      .login_right_now {
        text-align: center;
      }
      .el-form-item {
        padding: 0 0.6rem;
        display: flex;
        margin-right: 0;
      }
      .el-form-item__content {
        width: 100%;
        .el-select {
          width: 100%;
        }
      }
      .form_title {
        border-bottom: 1px solid#DCDFE6;
        margin-bottom: 0.5rem;
        h2 {
          font-size: 0.3rem;
          text-align: center;
          height: 1rem;
          line-height: 1rem;
          font-weight: 600;
          color: #444;
        }
      }
      .level_wrap {
        height: 40px;
        border-radius: 4px;
        padding-left: 18px;
        border: 1px solid #dcdfe6;
      }
      .placeholder_level {
        padding-left: 18px;
        color: #cacaca;
      }
      .tip {
        color: #999;
        font-size: 0.24rem;
        line-height: 24px;
      }
      .el-button--primary {
        width: 100%;
      }
      .phone_code_wrap {
        position: relative;
        .get_phone_code {
          position: absolute;
          right: 10px;
          top: 0;
          color: #910000;
          cursor: pointer;
        }
      }
    }
    .signup_btn {
      margin-top: 0.3rem;
    }
  }
  .level_modal_nr {
    padding: 0.2rem 0;
    height: 30vh;
    li {
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-bottom: 1px solid #f3f3f3;
    }
    .active_level {
      color: #910000;
    }
  }
}
</style>
